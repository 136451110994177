<template>
    <v-container fluid>
        <v-form @submit.prevent="savePartner">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>

                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'partner'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ $t('partner_profile') }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="2" class="mt-2 px-sm-6 text-center">
                                <make-avatar v-model="photo" :aspect-ratio="(1/1)"/>
                            </v-col>
                            <v-col cols="12" sm="9" md="10">
                                <v-row>
                                    <v-col class="py-0" cols="12" sm="12">
                                        <ValidationProvider ref="partner" rules="required|min:2|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="partner" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-account"
                                                          :label="$t('partner_name')" color="primary"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="phone" rules="phone"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="phoneRaw" type="tel" v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                          :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-phone"
                                                          :label="$t('phone')" color="primary"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="email" rules="required|email"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="email" type="email"
                                                          :error-messages="errors" :error="!valid"
                                                          :disabled="loading" prepend-icon="mdi-email"
                                                          label="Email" color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="12">
                                        <ValidationProvider  ref="role" rules="required"
                                                             v-slot="{ errors, valid }">
                                            <v-select v-model="role" :error="!valid" :error-messages="errors"
                                                      :disabled="loading || !can(['park_admin','coffee_admin', 'restaurant_admin', 'shop_admin', 'fitness_admin','cinema_admin'])"
                                                      :readonly="!can(['park_admin','coffee_admin', 'restaurant_admin', 'shop_admin', 'fitness_admin','cinema_admin'])"
                                                      :items="roleItems" item-text="name" item-value="id"
                                                      prepend-icon="mdi-account-hard-hat" color="primary"
                                                      :label="$t('role')"></v-select>
                                        </ValidationProvider>
                                    </v-col>



                                </v-row>

                            </v-col>




                            <v-col v-if="false" class="py-0" cols="12">
                                <ValidationProvider ref="companies" rules="min:1" v-slot="{ errors, valid }">
                                    <v-combobox v-model="companies" :items="companyItems"
                                                :error="!valid"
                                                :error-messages="errors" :search-input.sync="companySearching"
                                                item-text="name" item-value="id" :loading="loadingCompanies"
                                                prepend-icon="mdi-home-city" :disabled="loading"
                                                :label="$t('partner_companies')" @click:clear="companyItems=[]"
                                                @click="clearCompanies" @input="clearEmptyCompanyItems(companies)"
                                                color="primary" multiple return-object hide-selected clearable autocomplete="off">
                                        <template v-slot:item="{ attrs, item, parent, selected }">
                                                    <span class="search-list">
                                                        <span v-html="item.name"></span>
                                                        <span class="ml-2 hidden-is-empty" v-text="item.address_legal"></span>
                                                    </span>
                                        </template>
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <v-chip v-if="item === Object(item)"
                                                    v-bind="attrs" :input-value="selected"
                                                    :to="{ name: 'company.edit', params: { id: item.id }}"
                                                    @click:close="parent.selectItem(item)"
                                                    :color="item.active ? 'green lighten-4' : 'red lighten-4'"
                                                    :title="item.active ? $t('active') : $t('no_active')"
                                                    link close>
                                                <span v-html="item.name"></span>
                                            </v-chip>
                                        </template>
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ companySearching ? $t('nothing_found_by',
                                                        {'search': companySearching}) :
                                                        $t('nothing_found_company_name')}}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-combobox>
                                </ValidationProvider>
                            </v-col>

                            <v-col v-if="false" class="py-0" cols="12" >
                                <ValidationProvider ref="coffee_house" rules="min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="coffee_house" :items="coffee_houseItems"
                                                    :error-messages="errors" :error="!valid"
                                                    :search-input.sync="coffee_houseSearching"
                                                    item-text="name" item-value="id"
                                                    prepend-icon="mdi-coffee-maker"
                                                    append-icon="mdi-location-enter"
                                                    :loading="loadingCoffeeHouses" :disabled="loading"
                                                    :no-data-text="coffee_houseSearching ? $t('nothing_found_by',{'search': coffee_houseSearching}) : $t('nothing_found_name')"
                                                    :label="$t('coffee_house')"
                                                    @click:clear="coffee_houseItems=[]"
                                                    @click="clearCoffeeHouses"
                                                    @click:append="redirectToCoffeeHouse(coffee_house)"
                                                    color="primary" clearable autocomplete="off">
                                        <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.name"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.phone"></span>
                                            </span>
                                        </template>
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.name"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.phone"></span>
                                            </span>
                                        </template>
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>



                            <v-col v-if="can(['coffee_admin', 'restaurant_admin', 'shop_admin', 'fitness_admin','cinema_admin'])" cols="12">
                                <ValidationProvider ref="notes" rules="min:3|max:65535"
                                                    v-slot="{ errors, valid }">
                                    <v-textarea v-model="notes" type="text" :error-messages="errors"
                                                :disabled="loading" :label="$t('notes')" rows="5"
                                                color="primary" auto-grow clearable outlined>
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-3" cols="12">
                                <h3 class="headline">{{$t('partner_access_password')}}</h3>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="password" :rules="form_required + 'min:8'"
                                                    vid="password" v-slot="{ errors, valid }">
                                    <v-text-field v-model="password" :error="!valid && create" :disabled="loading"
                                                  :append-icon="passwordHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                                  :type="passwordHidden ? 'password' : 'text'" :error-messages="errors"
                                                  @click:append="() => (passwordHidden = !passwordHidden)"
                                                  prepend-icon="mdi-lock" :label="$t('enter_password')"
                                                  color="primary" clearable required></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="confirmation_password"
                                                    rules="required_if:password|confirmed:password"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="password_confirmation" :error="!valid" :disabled="loading"
                                                  :append-icon="passwordConfirmHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                                  :type="passwordHidden ? 'password' : 'text'" :error-messages="errors"
                                                  @click:append="() => (passwordConfirmHidden = !passwordConfirmHidden)"
                                                  prepend-icon="mdi-lock" :label="$t('repeat_password')"
                                                  color="primary" clearable required></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0 d-flex justify-start" cols="12" sm="6">
                                <v-switch v-model="deleted" :disabled="loading"
                                          :label="deleted ? $t('partner_deleted') : $t('partner_remove')"
                                          @change="deleted ? active = false : active" color="red"></v-switch>
                            </v-col>
                            <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12" sm="6">
                                <v-switch v-model="active" :disabled="loading || deleted"
                                          :label="$t('partner_active')"
                                          color="primary"></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-progress-linear v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading"
                               :loading="loading" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mask} from 'vue-the-mask'
    import MakeAvatar from '../components/MakeAvatar.vue'
    import {mapGetters} from "vuex"
    import debounce from "lodash/debounce"

    export default {
        name: 'PartnerForm',
        directives: {
            mask,
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            MakeAvatar,
        },
        inject: ['forceRerender'],
        data() {
            return {
                progress: 0,
                create: false,
                phoneRaw: null,
                partner: null,
                email: null,
                notes: null,
                photo: null,
                active: false,
                deleted: false,
                password: null,
                passwordHidden: true,
                password_confirmation: null,
                passwordConfirmHidden: true,
                loading: false,
                loadingCompanies: false,
                companies: [],
                companyItems: [],
                companySearching: null,
                role: null,
                roleItems: [],
                coffee_house: null,
                coffee_houseItems: [],
                coffee_houseSearching: null,
                loadingCoffeeHouses: false,





            }
        },
        computed: {
            ...mapGetters(['type_company_id','lang', 'phoneMask']),
            phone: function () {
                return this.changePhone(this.phoneRaw)
            },
            form_required: function () {
                if (this.create) {
                    return 'required|'
                }
                return ''
            },
            selectedPole: function () {
                let find = this.roleItems.filter(item => item.id === this.role)
                if (find && find.length === 1 && find[0].code === 'courier') {
                    return 'courier'
                }
                return null
            },
        },
        watch: {
            coffee_houseSearching: debounce(function (val) {
                if (val && !this.coffee_house) {
                    this.getCoffeeHouses(val)
                }
            }, 500),
            companySearching: debounce(function (val) {
                if (val) {
                    this.getCompanies(val)
                }
            }, 500),
            password(val) {
                if (val && val !== this.password_confirmation) {
                    this.$refs.confirmation_password.setErrors([
                        this.$t('validations.rules.confirmed', {'_field_': this.$t('validations.fields.confirmation_password')})
                    ])
                }
            },
            password_confirmation(val) {
                if (val && val !== this.password) {
                    this.$refs.password.setErrors([
                        this.$t('validations.rules.confirmed', {'_field_': this.$t('validations.fields.password')})
                    ])
                } else {
                    this.$refs.password.reset()
                }
            },
        },
        mounted() {
            this.getRoles()
         //   this.getTypeCompanies()
            this.checkCreate();
        },
        methods: {

            checkCreate() {
                if (this.$route.name === 'partner.create') {
                    this.create = true
                } else if (this.$route.name === 'profile' && this.$auth.check()) {
                    if (this.$auth.user().id) {
                        this.getPartner(this.$auth.user().id);
                    }
                } else {
                    if (this.$route.params.id) {
                        this.getPartner(this.$route.params.id);
                    }
                }
            },
            async getRoles() {
                let params = {};
                params.filter = 'getroles'
                params.type_company_id = this.type_company_id
                await this.$http
                    .get(`partner/role`, {
                        params: params,
                    })
                    .then(res => {
                        this.roleItems = res.body.data;
                    })
                    .catch(err => {
                        this.roleItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_roles'))
                    })
            },
            async getPartner(id) {
                var _this = this;
                this.progress = 0
                this.loading = true
                this.id = id
                await this.$http
                    .get(`partner/partner/${id}`, {
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.partner = res.body.data.name
                        this.phoneRaw = res.body.data.phone
                        this.email = res.body.data.email
                        this.photo = res.body.data.photo
                        this.active = res.body.data.active
                        this.deleted = res.body.data.deleted
                        this.notes = res.body.data.notes
                        this.companies = res.body.data.companies
                        this.role = res.body.data.role


                        let coffee_house = res.body.data.coffee_house
                        if (coffee_house && coffee_house.id) {
                            this.coffee_house = coffee_house.id
                            this.coffee_houseItems = [res.body.data.coffee_house]
                        } else {
                            this.coffee_houseItems = []
                        }



                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_partner'));
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async savePartner() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.partner) {
                    formData.append('partner', this.partner)
                }
                if (this.phone) {
                    formData.append('phone', this.phone)
                }
                if (this.email) {
                    formData.append('email', this.email)
                }
                if (this.password) {
                    formData.append('password', this.password)
                }
                if (this.notes) {
                    formData.append('notes', this.notes)
                }
                if (this.active) {
                    formData.append('active', 1)
                }
                if (this.deleted) {
                    formData.append('deleted', 1)
                }
                if (this.role) {
                    formData.append('role', this.role)
                }



                if (this.companies && this.companies.length > 0) {
                    for (let i in this.companies) {
                        if (this.companies[i].id !== undefined && this.companies[i].id > 0) {
                            formData.append(`companies[${i}]`, this.companies[i].id)
                        }
                    }
                }



                if (this.coffee_house) {
                    if (this.coffee_house.id) {
                        formData.append('coffee_house', this.coffee_house.id)
                    } else {
                        formData.append('coffee_house', this.coffee_house)
                    }
                }



                if (this.photo) {
                    if (this.photo.length > 250) {
                        var mimeType = this.getMimeType(this.photo);
                        var blob = this.dataURL64toBlob(this.photo);
                        if (mimeType && blob) {
                            formData.append('photo', blob, mimeType);
                        }
                    } else {
                        formData.append('photo', this.photo);
                    }
                }
                if (this.$route.params.id) {
                    // Save
                    await this.$http
                        .put(`partner/partner/${this.$route.params.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('partner_has_been_updated'));
                            //this.forceRerender();
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('partner_has_not_been_updated'));
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('partner/partner', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('partner_has_been_added'));
                            if (res && res.body && res.body.data && res.body.data.id) {
                                this.$router.push({
                                    name: 'partner.edit',
                                    params: {
                                        id: res.body.data.id
                                    }
                                })
                            } else {
                                this.$router.push({name: 'partner'})
                            }
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('partner_has_not_been_added'));
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },


            clearCoffeeHouses() {
                if (!this.coffee_house) {
                    this.coffee_houseItems = []
                }
            },
            redirectToCoffeeHouse(val) {
                if (val) {
                    this.$router.push({
                        name: 'coffee_house.edit',
                        params: {
                            id: val
                        }
                    })
                }
            },
            async getCoffeeHouses(str) {
                if (str) {
                    this.loadingCoffeeHouses = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.coffee_house = str
                    }
                    await this.$http
                        .get("partner/coffee_house", {
                            params: params,
                        })
                        .then(res => {
                            this.coffee_houseItems = res.body.data
                        })
                        .catch(err => {
                            this.coffee_houseItems = []
                        })
                        .finally(end => {
                            this.loadingCoffeeHouses = false
                        })
                }
            },





            clearEmptyCompanyItems(items) {
                if (items && items.length > 0) {
                    this.companies = items.filter(item => typeof item === 'object')
                }
            },
            clearCompanies() {
                if (!this.companies) {
                    this.companyItems = []
                }
            },
            async getCompanies(str) {
                if (str) {
                    this.loadingCompanies = true
                    let params = {};
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.company = str;
                    }
                    await this.$http
                        .get("partner/company", {
                            params: params,
                        })
                        .then(res => {
                            this.companyItems = res.body.data;
                        })
                        .catch(err => {
                            this.companyItems = []
                            this.$toastr.error(this.$t('failed_to_get_list_companies'))
                        })
                        .finally(end => {
                            this.loadingCompanies = false
                        })
                }
            },




        }
    }
</script>
